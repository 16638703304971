import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function PenetrationTesting() {
  return (
    <Layout>
      <SEO
        keywords={[
          `hack guard`,
          `penetration testing`,
          `mobile application testing`,
          `API testing`,
          `Cybersecurity`,
        ]}
        title="Application Penetration Testing"
      />

      <section className="flex flex-col items-center md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <h1 className="p-4 mb-4 border-b-4 border-teal-700 text-bold text-3xl">
            Application Penetration Testing
          </h1>
          <p className="pl-4 font-serif leading-loose text-justify border-l-4 border-teal-700">
            To stay competitive you need to innovate. However, innovation could
            introduce some gaps and increase your threat exposure. Hence, a
            proper assessment of your security posture is essential to prevent
            adversaries from compromising your systems, stealing data and
            damaging your business. Attackers are getting more clever,
            stealthier and their attacks are getting more complex, it is
            imperative to regularly test your infrastructure, processes, and
            employees. Hack Guard can be commissioned to simulate attacks on
            your Website, Web API and Mobile Application using standard NIST,
            OWASP methodologies.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default PenetrationTesting;
